<template>
  <el-main>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
      <el-form-item label="兑换规则：" prop="subscription_ratio">
        <el-input v-model="ruleForm.subscription_ratio"></el-input><span class="ml txt">积分兑换1元人民币</span>
      </el-form-item>
      <el-form-item label="分享页面到微信群：" prop="share">
        <!--<el-select placeholder="请选择" style="width:100px">
          <el-option label="每天" value="0"> </el-option>
          <el-option label="全部" value="1"> </el-option>
        </el-select>-->
        <el-input style="width:150px" v-model="ruleForm.share.count"></el-input>
        <span class="ml mr txt">次,送</span>
        <el-input style="width:150px" class="mr" v-model="ruleForm.share.integral"></el-input>
        <span class="txt">积分</span>
      </el-form-item>
      <el-form-item label="登录积分：" prop="login">
        <span class="txt mr">每天登录送</span>
        <el-input style="width:150px" class="mr" v-model="ruleForm.login.integral"></el-input>
        <span class="txt mr">积分,最多</span>
        <el-input style="width:150px" class="mr" v-model="ruleForm.login.max_count"></el-input>
        <span class="txt mr">次</span>
        <span class="tips">最多次数为每天可以登录拿积分的最多次数</span>
      </el-form-item>
      <el-form-item label="签到积分：" prop="signin">
        <span class="txt mr">每天签到送</span>
        <el-input style="width:150px" class="mr" v-model="ruleForm.signin.integral"></el-input>
        <span class="txt mr">积分,连续</span>
        <el-input style="width:150px" class="mr" v-model="ruleForm.signin.continuous_day"></el-input>
        <span class="txt mr">天后,每天签到送</span>
        <el-input style="width:150px" class="mr" v-model="ruleForm.signin.continuous_integral"></el-input>
        <span class="txt">积分</span>
      </el-form-item>
      <el-form-item label="发布时价：" prop="release_quotation">
        <span class="txt mr">每天上传前</span>
        <el-input style="width:50px" class="mr" v-model="ruleForm.release_quotation.count"></el-input>
        <span class="txt mr">个,每个送</span>
        <el-input style="width:50px" class="mr" v-model="ruleForm.release_quotation.front_integral"></el-input>
        <span class="txt mr">积分,后面每上传1个送</span>
        <el-input style="width:50px" class="mr" v-model="ruleForm.release_quotation.back_integral"></el-input>
        <span class="txt mr">积分</span>
        <span class="tips">如果一个发布的时价含有多条数据,算多条积分累加</span>
      </el-form-item>
      <el-form-item label="发布资讯：" prop="release_information">
        <span class="txt mr">每天上传前</span>
        <el-input style="width:50px" class="mr" v-model="ruleForm.release_information.count"></el-input>
        <span class="txt mr">篇,每篇送</span>
        <el-input style="width:50px" class="mr" v-model="ruleForm.release_information.front_integral"></el-input>
        <span class="txt mr">积分,后面每上传1篇送</span>
        <el-input style="width:50px" class="mr" v-model="ruleForm.release_information.back_integral"></el-input>
        <span class="txt mr">积分</span>
      </el-form-item>
      <el-form-item label="积分规则：" prop="content">
        <RichText :richTxt="ruleForm.content" @soninfo="val => (ruleForm.content = val)"></RichText>
      </el-form-item>
    </el-form>
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
import _ from 'lodash'
export default {
  components: {
    RichText,
    Preservation
  },
  data () {
    function checkShare (rule, value, callback) {
      if (value.count === '') {
        callback(new Error('请输入次数'))
      } else if (value.integral === '') {
        callback(new Error('请输入积分数'))
      } else {
        callback()
      }
    }
    function checkLogin (rule, value, callback) {
      if (value.integral === '') {
        callback(new Error('请输入积分数'))
      } else if (value.max_count === '') {
        callback(new Error('请输入最多次数'))
      } else {
        callback()
      }
    }
    function checkSignin (rule, value, callback) {
      if (value.integral === '') {
        callback(new Error('请输入积分数'))
      } else if (value.continuous_day === '') {
        callback(new Error('请输入连续天数'))
      } else if (value.continuous_integral === '') {
        callback(new Error('请输入连续签到赠送积分'))
      } else {
        callback()
      }
    }
    function checkQuotation (rule, value, callback) {
      if (value.count === '') {
        callback(new Error('请输入次数'))
      } else if (value.front_integral === '') {
        callback(new Error('请输入前面批次所送积分'))
      } else if (value.back_integral === '') {
        callback(new Error('请输入后面批次所送积分'))
      } else {
        callback()
      }
    }
    function checkInformation (rule, value, callback) {
      if (value.count === '') {
        callback(new Error('请输入次数'))
      } else if (value.front_integral === '') {
        callback(new Error('请输入前面批次所送积分'))
      } else if (value.back_integral === '') {
        callback(new Error('请输入后面批次所送积分'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        subscription_ratio: "",
        share: {
          count: "",
          integral: ""
        },
        login: {
          integral: "",
          max_count: ""
        },
        signin: {
          integral: "",
          continuous_day: "",
          continuous_integral: ""
        },
        release_quotation: {
          count: "",
          front_integral: "",
          back_integral: ""
        },
        release_information: {
          count: "",
          front_integral: "",
          back_integral: ""
        },
        content: ""
      },
      rules: {
        subscription_ratio: [{ required: true, message: '请输入积分数量', trigger: 'blur' }],
        share: [{ validator: checkShare, trigger: 'blur' }],
        login: [{ validator: checkLogin, trigger: 'blur' }],
        signin: [{ validator: checkSignin, trigger: 'blur' }],
        release_quotation: [{ validator: checkQuotation, trigger: 'blur' }],
        release_information: [{ validator: checkInformation, trigger: 'blur' }],
        content: [{ required: true, message: '请输入积分规则', trigger: 'blur' }],
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 获取积分设置
    getInfo () {
      this.$axios.post(this.$api.gateway.IntegralSet.info)
        .then(res => {
          if (res.code === 0) {
            for (const key in this.ruleForm) {
              if (key === 'content') {
                this.ruleForm[key] = res.result[key]
              } else {
                this.ruleForm[key] = JSON.parse(res.result[key])
              }
            }
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    // 保存积分设置
    Preservation () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let obj = _.cloneDeep(this.ruleForm)
          console.log(this.ruleForm);
          this.$axios.post(this.$api.gateway.IntegralSet.edit, obj)
            .then(res => {
              console.log(obj);
              if (res.code === 0) {
                this.$message.success('保存成功')
              } else {
                this.$message.error(res.msg)
              }
            })
        }
      })

    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  height: 100%;
  padding-bottom: 80px;

  .el-input {
    width: 500px;
  }

  .txt {
    color: #333;
    font-size: 12px;
  }

  .tips {
    color: #ccc;
    font-size: 12px;
  }

  .mr {
    margin-right: 14px;
  }

  .ml {
    margin-left: 14px;
  }
}
</style>